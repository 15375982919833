
// import React from "react";
// import { Modal } from "react-bootstrap";
// import { FaTimes } from "react-icons/fa";

// const CommonModal = ({
//   show,
//   handleClose,
//   title,
//   children,
//   size = "md",
//   className,
//   onHide,
//   closeIcon = true,
// }) => {
//   const modalStyles = {
//     // Converted CSS from SCSS
//     padding: "15px",
//     position: "relative",
//     minHeight: "290px",
//     // ... other styles from your SCSS

//     // Welcome wrapper styles
//     "&.welcome_wrapper .modal-dialog": {
//       maxWidth: "900px",
//     },
//     "&.welcome_wrapper .modal-content": {
//       padding: "0",
//       borderRadius: "10px",
//       overflow: "hidden",
//     },
//     "&.welcome_wrapper .image_wrap": {
//       height: "470px",
//     },
//     "&.welcome_wrapper .image_wrap img": {
//       width: "100%",
//       height: "100%",
//       objectFit: "contain",
//     },
//     "&.welcome_wrapper .modal_close_button": {
//       border: "1px solid",
//       top: "3px",
//       borderRadius: "50%",
//       width: "30px",
//       height: "30px",
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//     },

//     // Reupload loader styles
//     "&.reupload_loader .testPage_upload_paper": {
//       position: "relative",
//     },
//     "&.reupload_loader .percentage_loader": {
//       left: "0",
//       right: "0",
//       height: "162px",
//       width: "100%",
//       zIndex: "9",
//       backgroundColor: "#fff",
//       "&:after": {
//         backgroundColor: "transparent",
//       },
//     },
//     // ... other styles from your SCSS
//   };

//   return (
//     <div style={modalStyles} className="common_modal">
//       <Modal
//         backdrop="static"
//         size={size}
//         centered
//         show={show}
//         onHide={handleClose}
//         className={`common_modal ${className}`}
//       >
//         <div className="modal_content">
//           {closeIcon && (
//             <span
//               className="modal_close_button"
//               onClick={onHide || handleClose}
//             >
//               <FaTimes />
//             </span>
//           )}
//           {title && (
//             <div className="modal_title">
//               <h4>{title}</h4>
//             </div>
//           )}
//           {children}
//         </div>
//       </Modal>
//     </div>
//   );
// };

// export default CommonModal;


import React from "react";
 import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

function CommonModal({
  show,
  handleClose,
  title,
  children,
  size = "md",
  className,
  onHide,
  closeIcon = true,
}) {
  // const SIZE = { sm: "100px", md: "200px" };

  return (
    <div className="common_modal">
      <Modal
        backdrop="static"
        size={size}
        centered
        show={show}
        onHide={handleClose}
        className={`common_modal ${className}`}
      >
        <div className="modal_content">
          {closeIcon && (
            <span
              className="modal_close_button"
              onClick={onHide || handleClose}
            >
              <FaTimes />
            </span>
          )}
          {title && (
            <div className="modal_title">
              <h4>{title}</h4>
            </div>
          )}
          {children}
        </div>
      </Modal>
    </div>
  );
}

export default CommonModal;
