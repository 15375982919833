
import React, { Component } from "react";

class VideoCipherPlayer extends Component {

  

  constructor(props) {
    console.log("in cipher");
    super(props);
    this.state = {
      videoObject: null,
    };
  }

  componentDidMount() {
    if (window.VdoPlayer) {
      return this.loadPlayer();
    }
    const playerScript = document.createElement("script");
    playerScript.src =
      "https://player.vdocipher.com/playerAssets/1.6.10/vdo.js";
    document.body.appendChild(playerScript);
    playerScript.addEventListener("load", () => {
      console.log("this is vdocipher player");
      return this.loadPlayer();
    });
  }

  loadPlayer() {
    window.playerContainer = document.getElementById("cipher-player");
    window.playerInstance = new window.VdoPlayer({
      otp: this.props.otp,
      playbackInfo: this.props.playbackInfo,
      theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
      container: document.getElementById("cipher-player"),
      
    });
    
    // // Access the VdoPlayer instance and attach the timeupdate event

    // // Access the VdoPlayer instance and attach the timeupdate event
    // playerInstance.play("timeupdate", (currentTime) => {
    //   this.setState({ currentTime }); // Update the state with the current time
    // });
  }

  render() {
    return <div className="player" id="cipher-player"></div>;
  }
}

export default VideoCipherPlayer;



// VideoCipherPlayer.js
// import React, { useEffect, useRef } from 'react';

// const VideoCipherPlayer = ({ otp, playbackInfo }) => {
//   console.log(otp,"otp")
//   console.log(playbackInfo,"playbackInfo")
//   const playerContainerRef = useRef();

//   useEffect(() => {
//     console.log("VideoCipherPlayer: otp and playbackInfo", otp, playbackInfo);

//     const loadPlayer = async () => {
//       if (!otp || !playbackInfo) {
//         console.error("Missing OTP or Playback Info");
//         return;
//       }

//       // Rest of the script loading logic
//     };

//     loadPlayer();
//   }, [otp, playbackInfo]);

//   return (
//     <div className="player" id="cipher-player" ref={playerContainerRef}>
//       {console.log("VideoCipherPlayer component rendered")}
//     </div>
//   );
// };

// export default VideoCipherPlayer;












// import React, { useEffect } from 'react';

// const VideoCipherPlayer = ({ videoId }) => {
//     useEffect(() => {
//         // Function to load VideoCipher script
//         const loadVideoCipherScript = () => {
//             const script = document.createElement('script');
//             script.src = 'path_to_videocipher_js';
//             script.async = true;
//             script.onload = () => initializeVideoCipher(videoId);
//             document.body.appendChild(script);
//         };

//         // Initialize VideoCipher with the specific video ID
//         const initializeVideoCipher = (videoId) => {
//             // Code to initialize VideoCipher with the given videoId
//             // This will depend on VideoCipher's specific implementation details
//         };

//         loadVideoCipherScript();
//     }, [videoId]);

//     return (
//         <div id="videocipher-container">
//             {/* VideoCipher will likely attach the video player here */}
//         </div>
//     );
// };

// export default VideoCipherPlayer;


