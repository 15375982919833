import React, { useState,useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthToken,AuthToken1, BASE_URL,BASE_URL1 } from "../Constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';
import DataTable from 'react-data-table-component'; 
import Box from '@mui/material/Box';
import Button from 'react-bootstrap/Button';
import { CSVLink } from 'react-csv';
import TextField from '@mui/material/TextField';
import Navbar from './Navbar';
 
function CourseMaping() {
 

  const[Rdata, setRdata] = useState("noloader");
  const [data,setdata]=useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();


  
  const columns = [
      
    // { name: 'Course Title', selector: 'CourseTitle',width: "400px", sortable: true },
      { name: 'Course Title', 
        width:'400px', 
        selector: 'CourseTitle', sortable: true,
        cell: (row) => <div style={{ width: '400px'}}>{row.CourseTitle}</div>, 
      },
      { name: 'Target Year', selector: 'TargetYear',width: "200px", sortable: true },
      {
        name: 'Start Date',
        selector: 'StartDate',
        sortable: true,
        width: '150px',
        cell: (row) => {
          // Convert the AdmissionDate to the desired format (e.g., "4 Jan 2023")
          const formattedDate = formatDate(row.StartDate);
          return <div style={{ width: '150px' }}>{formattedDate}</div>;
        },
      },
      {
        name: 'End Date',
        selector: 'EndDate',
        sortable: true,
        width: '150px',
        cell: (row) => {
          // Convert the AdmissionDate to the desired format (e.g., "4 Jan 2023")
          const formattedDate = formatDate(row.EndDate);
          return <div style={{ width: '150px' }}>{formattedDate}</div>;
        },
      },
      {
        name: 'Actions',
        cell: (row) => (
          <div >
            <span style={{cursor:"pointer",color:"blue"}} onClick={() => handlenavigate(row.CourseID,row.AffiliateID)}>View Details</span>&nbsp;&nbsp;
 
          </div>
        ),
      },
  ];

  const handlenavigate = (CourseID,AffiliateID) => {
    navigate(`/lectures/${CourseID}/${1003}`);
  };

  // const formatDate = (remarkdate) => {
  //   const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  //   // Parse the admissionDate in the format "DD/MM/YYYY"
  //   const [day,monthIndex,year] = remarkdate.split('/');
  
  //   // Convert monthIndex to a string representation of the month (e.g., "Jan")
  //   const month = months[parseInt(monthIndex, 10) - 1];
  
  //   // Create the formatted date string (e.g., "4 Jan 2023")
  //   return `${parseInt(day, 10)} ${month} ${year}`;
  // };
  function formatDate(dateString) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const monthIndex = date.getMonth();
    const month = months[monthIndex];
    const year = date.getFullYear().toString().slice(-2);
    return `${day} ${month} ${year}`;
}

   

      ////////////////////////////////////////////

      const fetchData = () => {
        setRdata("showloader");
        fetch(BASE_URL1 + "/client/affiliate-assigned-courses", {
          method: "POST",
          body: JSON.stringify({ affiliateid: localStorage.getItem('AffiliateID') }),
          headers: {
            "Content-Type": "application/json",
            "API_KEY": AuthToken1,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.data && data.data.affiliateAssignedCourses !== null) {
              // Extracting 'Courses' data from the API response
              const coursesData = data.data.affiliateAssignedCourses.map(course => course.Courses);
      
              // Setting 'Courses' data using setdata
              setdata(coursesData);
              setRdata("noloader");
            } else {
              // Handle the case when there are no courses
              setdata([]);
              setRdata("noloader");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setRdata("noloader");
          });
      };
      useEffect(() => {  
        fetchData();        
         },[]);
      
      


//List//


// const handleSearch = () => {
//   if (searchQuery.trim() !== "") {
//     const filteredData = data.filter((item) => {
//       return (
//         (item.CourseTitle ? item.CourseTitle.toLowerCase().includes(searchQuery.toLowerCase()) : false) ||
//         (item.TargetYear ? item.TargetYear.toString().includes(searchQuery.toString()) : false) ||
//         (item.CourseStartDate ? formatDate(item.CourseStartDate).includes(searchQuery.toString()) : false) || // Updated
//         (item.CourseEndDate ? formatDate(item.CourseEndDate).includes(searchQuery.toString()) : false) // Updated
//       );
//     });
//     setSearchResults(filteredData);
//   } else {
//     setSearchResults(data); // Set searchResults to the original data array
//   }
// };

const handleSearch = () => {
  if (searchQuery.trim() !== "") {
    const filteredData = data.filter((item) => {
      const startDate = item.StartDate ? formatDate(item.StartDate).toLowerCase() : ''; 
      const endDate = item.EndDate ? formatDate(item.EndDate).toLowerCase() : ''; 
      return (
        (item.CourseTitle ? item.CourseTitle.toLowerCase().includes(searchQuery.toLowerCase()) : false) ||
        (item.TargetYear ? item.TargetYear.toString().includes(searchQuery.toString()) : false) ||
        (startDate.includes(searchQuery.toLowerCase())) || 
        (endDate.includes(searchQuery.toLowerCase())) 
      );
    });
    setSearchResults(filteredData);
  } else {
    setSearchResults(data); 
  }
};



const currentDate = new Date();
const formattedDate = `${currentDate.getDate()}${currentDate.toLocaleString('default', { month: 'long' })}${currentDate.getFullYear()}`; 
const csvData = data.map(item => ({
  'Course Title': item.CourseTitle,
  'Start Date': item.StartDate,
  'End Date': item.EndDate,
}));

  return (
    <>
    <Navbar/>
  <div className="nk-content nk-content-fluid">
                        <div className="container-xl wide-xl">
                        <div className="nk-content-inner">
                          <div className="nk-content-body">


                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                                  <div className="nk-block-head-content">
                                      <h3 className="nk-block-title page-title">My Courses</h3>
                                      <div className="nk-block-des text-soft">
                                          <p>Welcome to My Courses.</p>
                                      </div>
                                  </div>
                                  <div className="nk-block-head-content">
                                      <div className="toggle-wrap nk-block-tools-toggle">
                                          <a href="#" className="btn btn-icon btn-trigger toggle-expand me-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
                                          <div className="toggle-expand-content" data-content="pageMenu">
                                              <ul className="nk-block-tools g-3">
                                                  {/* <li><a onClick={() => document.querySelector('.csv-link').click()} className="btn btn-white btn-dim btn-outline-primary"><em class="icon ni ni-download-cloud"></em><span>Export</span></a></li> */}
                                                  {data.length && data.length > 0 ? (
                                                    <li>
                                                      
                                                       <a onClick={() => document.querySelector('.csv-link').click()} className="btn btn-white btn-dim btn-outline-primary">
                                                        <em class="icon ni ni-download-cloud rotate-up"></em>
                                                        <span>Export</span>
                                                      </a>
                                                    </li>
                                                    
                                                ) : null}
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>

                              

                          {data.length > 0 ?
                              <CSVLink className="csv-link" data={csvData} filename={`Course_Maping-${formattedDate}.csv`} style={{display: 'none'}}>
                                  Export to CSV
                              </CSVLink> :""
                          } 

                               
                              <div className="col-md-12"> 
                              <div>
                                  <TextField
                                  type="text"
                                  id="txtsearch"
                                  size="small"
                                  label="Search"
                                  placeholder="Search"
                                  value={searchQuery}
                                  onChange={(e) => {
                                    setSearchQuery(e.target.value); // Update searchQuery state
                                    handleSearch(); // Call handleSearch to update the list
                                  }}
                                  
                                  />&nbsp;&nbsp;&nbsp;&nbsp;
                                     <Button class="btn btn-primary" onClick={handleSearch} style={{height:"39px"}}>
                                      Search 
                                  </Button>   
                                  

                                  </div>
                                  <br></br>

                                  <div className="card card-bordered"> 
                                        <div className="col-md-12">  
                                          <DataTable
                                          columns={columns}
                                          data={searchResults.length > 0 ? searchResults : data}
                                         // getRowId={(row) =>  row.StudentID}
                                          pagination={true}
                                          />
                                        </div>
                                  </div>
                                  
                              </div>     
                        </div>  
                        {Rdata === "showloader"?
                                              <Backdrop   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}  open>
                                              <CircularProgress color="inherit" />
                                          </Backdrop>    :""
                                          }
                        <ToastContainer />
                         
                  </div>
                 
          </div>
  </div>              
  </>

  )
}

export default CourseMaping
