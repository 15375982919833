import React from 'react'

function UnAuthorized() {
  return (
    <form className="App-logo"> 
    <div className="nk-app-root">
        <div className="nk-main">
            <div className="nk-wrap nk-wrap-nosidebar">
                    <div className="nk-content ">
                        <div className="nk-block nk-block-middle nk-auth-body  wide-xss">
                           
                            <div className="card card-bordered">    
                                <div className="card-inner card-inner-lg">
                                <div className="brand-logo pb-4 text-center">
                                    <img width={80} className="logo" src="/Images/srlogo.png" alt="logo" />
                                </div>
                                    <div className="nk-block-head">
                                        <div className="nk-block-head-content" style={{textAlign:"center"}}>
                                            <h5 className="nk-block-title" style={{color:"red"}}>"You are not authorize to view this page"</h5>
                                        </div>
                                    </div>
                                  
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                   
                </div>
            </div>
        </div>
    
        </form>
  )
}

export default UnAuthorized
