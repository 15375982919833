import {React, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { LOGO_URL } from '../Constants';

const Navbar = () => {

    const navigate = useNavigate();
    const AdminName=localStorage.getItem('AffiliateName');
    
    useEffect(() => {
        if(AdminName == null){
            console.log("Checklogin");
            navigate('/');
        }
    });

    function handleLogoutClick() {
        localStorage.clear();        
        navigate('/');
      }

      function NavigateChangePass() {
   
        navigate('/change-password');
      }
  return (
    <>  
     
            <div class="nk-header nk-header-fluid is-theme">
                <div class="container-xl wide-xl">
                    <div class="nk-header-wrap">
                        <div class="nk-menu-trigger me-sm-2 d-lg-none">
                            <a href="#" class="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em class="icon ni ni-menu"></em></a>
                        </div>
                        <div class="nk-header-brand">
                            <a href="html/index.html" class="logo-link">
                                <img class="logo-light logo-img" src={LOGO_URL}  srcset="./images/logo2x.png 2x" alt="logo"/>
                                <img class="logo-dark logo-img" src={LOGO_URL} srcset="./images/logo-dark2x.png 2x" alt="logo-dark"/>
                            </a>
                        </div>
                        <div class="nk-header-menu" data-content="headerNav">
                            <div class="nk-header-mobile">
                                <div class="nk-header-brand">
                                    <a href="html/index.html" class="logo-link">
                                        <img class="logo-light logo-img" src={LOGO_URL} srcset="./images/logo2x.png 2x" alt="logo"/>
                                        <img class="logo-dark logo-img" src={LOGO_URL} srcset="./images/logo-dark2x.png 2x" alt="logo-dark"/>
                                    </a>
                                </div>
                                <div class="nk-menu-trigger me-n2">
                                    <a href="#" class="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em class="icon ni ni-arrow-left"></em></a>
                                </div>
                            </div>
                            <ul class="nk-menu nk-menu-main ui-s2">

                                <li class="nk-menu-item has-sub">
                                    <a href="/course-categories" class="nk-menu-link">
                                        <span class="nk-menu-text"></span>
                                    </a>
                                    
                                </li> 
                                <li class="nk-menu-item has-sub">
                                    <a href="/course-vertical" class="nk-menu-link">
                                        <span class="nk-menu-text"></span>
                                    </a>
                                    
                                </li> 
                                <li class="nk-menu-item has-sub">
                                    <a href="/gst-master" class="nk-menu-link">
                                        <span class="nk-menu-text"></span>
                                    </a>
                                    
                                </li> 


                                <li class="nk-menu-item has-sub">
                                    <a href="/course-maping" class="nk-menu-link">
                                        <span class="nk-menu-text">Courses</span>
                                    </a>
                                    {/* <ul class="nk-menu-sub">
                                        <li class="nk-menu-item">
                                            <a href="/inventory-management" class="nk-menu-link">
                                                <span class="nk-menu-text">Inventory Management</span>
                                            </a>
                                        </li> 
                                        <li class="nk-menu-item">
                                            <a href="/po-details" class="nk-menu-link">
                                                <span class="nk-menu-text">Purchase Order</span>
                                            </a>
                                        </li> 
                                        <li class="nk-menu-item">
                                            <a href="/license-details" class="nk-menu-link">
                                                <span class="nk-menu-text">Tablet License</span>
                                            </a>
                                        </li> 
                                        <li class="nk-menu-item">
                                            <a href="/staff-tablets" class="nk-menu-link">
                                                <span class="nk-menu-text">Staff Tablets</span>
                                            </a>
                                        </li> 
                                         
                                         
                                    </ul>  */}
                                </li> 
                                {/* <li class="nk-menu-item has-sub">
                                    <a href="#" class="nk-menu-link nk-menu-toggle">
                                        <span class="nk-menu-text">Student Tablets</span>
                                    </a>
                                    <ul class="nk-menu-sub">
                                        <li class="nk-menu-item">
                                            <a href="/address-verification" class="nk-menu-link"><span class="nk-menu-text">Address Verification</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="" class="nk-menu-link"><span class="nk-menu-text">Tabs to be Allocated </span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="/dispatch-pdf-print" class="nk-menu-link"><span class="nk-menu-text">Dispatch PDF Print</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="/tracking-details-entry" class="nk-menu-link"><span class="nk-menu-text">Tracking Details Entry</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="/tnc-form-entry" class="nk-menu-link"><span class="nk-menu-text">T&C Form Entry</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="/completed-tab" class="nk-menu-link"><span class="nk-menu-text">Completed Tabs</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="/search-tnc" class="nk-menu-link"><span class="nk-menu-text">Search T&C</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="/tnc-list" class="nk-menu-link"><span class="nk-menu-text">TNC List</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="" class="nk-menu-link"><span class="nk-menu-text">Student Invoice</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="/dispatch-pdf-re-print" class="nk-menu-link"><span class="nk-menu-text">Dispatch Reprint</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="" class="nk-menu-link"><span class="nk-menu-text">Change Courses</span></a>
                                        </li>
                                    </ul>
                                </li>  */}

                                {/* <li class="nk-menu-item has-sub">
                                    <a href="#" class="nk-menu-link nk-menu-toggle">
                                        <span class="nk-menu-text">Affiliates Tablet</span>
                                    </a>
                                    <ul class="nk-menu-sub">
                                       
                                        
                                        <li class="nk-menu-item">
                                            <a href="/affiliates-tablet" class="nk-menu-link">
                                                <span class="nk-menu-text">Affiliates Tablets</span>
                                            </a>
                                        </li> 
                                        <li class="nk-menu-item">
                                            <a href="" class="nk-menu-link">
                                                <span class="nk-menu-text">Transfer Tablets</span>
                                            </a>
                                        </li> 
                                        <li class="nk-menu-item">
                                            <a href="" class="nk-menu-link"><span class="nk-menu-text">Handover Affiliate Tablets</span></a>
                                        </li> 
                                        
                                    </ul> 
                                </li>  */}

                                {/* <li class="nk-menu-item has-sub">
                                    <a href="#" class="nk-menu-link nk-menu-toggle">
                                        <span class="nk-menu-text">Unlock Tablets</span>
                                    </a>
                                    <ul class="nk-menu-sub">
                                        <li class="nk-menu-item">
                                            <a href="/unlock-tablets" class="nk-menu-link"><span class="nk-menu-text">Unlock Tablet List</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="/forcefull-unlock-tablet" class="nk-menu-link"><span class="nk-menu-text">Forcefull Unlock</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="" class="nk-menu-link"><span class="nk-menu-text">Unlock by Course</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="" class="nk-menu-link"><span class="nk-menu-text">Import Unlock Data</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="" class="nk-menu-link"><span class="nk-menu-text">Reassign Tablet</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="/reassign-tablet-list" class="nk-menu-link"><span class="nk-menu-text">Reassign Tablet List</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="" class="nk-menu-link"><span class="nk-menu-text">Unassign Tablet</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="/returned-tab" class="nk-menu-link"><span class="nk-menu-text">Returned Tabs</span></a>
                                        </li>
                                    </ul> 
                                </li>  */}

                                {/* <li class="nk-menu-item has-sub">
                                    <a href="#" class="nk-menu-link nk-menu-toggle">
                                        <span class="nk-menu-text">Reports</span>
                                    </a>
                                    <ul class="nk-menu-sub">
                                        <li class="nk-menu-item">
                                            <a href="/status-report" class="nk-menu-link"><span class="nk-menu-text">Status Report</span></a>
                                        </li>
                                    </ul> 
                                </li>  */}
                                {/* <li class="nk-menu-item has-sub">
                                    <a href="#" class="nk-menu-link nk-menu-toggle">
                                        <span class="nk-menu-text">Knox</span>
                                    </a>
                                    <ul class="nk-menu-sub">
                                        <li class="nk-menu-item">
                                            <a href="/assign-orgid" class="nk-menu-link"><span class="nk-menu-text">Assign Organization ID</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="" class="nk-menu-link"><span class="nk-menu-text">Student List</span></a>
                                        </li>
                                        <li class="nk-menu-item">
                                            <a href="" class="nk-menu-link"><span class="nk-menu-text">IMEI Student List</span></a>
                                        </li>
                                    </ul> 
                                </li>  */}
                                
                            </ul>
                        </div>
                        <div class="nk-header-tools">
                            <ul class="nk-quick-nav">
                                
                                <li class="dropdown user-dropdown order-sm-first">
                                    <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">
                                        <div class="user-toggle">
                                            <div class="user-avatar sm">
                                                <em class="icon ni ni-user-alt"></em>
                                            </div>


                                            <div class="user-info d-none d-xl-block">
                                                <div class="user-name">{AdminName}</div>
                                                <div class="user-name">
                                                <a href="javascript:void(0);" onClick={handleLogoutClick}>
                                                Logout
                                                </a>
                                                </div>
                                                {/* {/ <div class="user-name dropdown-indicator">{AdminName}</div> /} */}
                                            </div>
                                            
                                            {/* <div class="user-info d-none d-xl-block">
                                                <div class="user-status">Administrator</div>
                                                <div class="user-name">{AdminName}</div>
                                                <div class="user-name dropdown-indicator">{AdminName}</div>
                                            </div> */}
                                        </div>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-md dropdown-menu-end dropdown-menu-s1 is-light">
                                        <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                            <div class="user-card">
                                                <div class="user-avatar">
                                                    <span>AB</span>
                                                </div>
                                                <div class="user-info">
                                                    <span class="lead-text">Abu Bin Ishtiyak</span>
                                                    <span class="sub-text">info@softnio.com</span>
                                                </div>
                                                <div class="user-action">
                                                    <a class="btn btn-icon me-n2" href="html/user-profile-setting.html"><em class="icon ni ni-setting"></em></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dropdown-inner user-account-info">
                                            <h6 class="overline-title-alt">Account Balance</h6>
                                            <div class="user-balance">1,494.23 <small class="currency currency-usd">USD</small></div>
                                            <div class="user-balance-sub">Locked <span>15,495.39 <span class="currency currency-usd">USD</span></span></div>
                                            <a href="#" class="link"><span>Withdraw Balance</span> <em class="icon ni ni-wallet-out"></em></a>
                                        </div>
                                        <div class="dropdown-inner">
                                            <ul class="link-list">
                                                <li><a href="html/user-profile-regular.html"><em class="icon ni ni-user-alt"></em><span>View Profile</span></a></li>
                                                <li><a href="html/user-profile-setting.html"><em class="icon ni ni-setting-alt"></em><span>Account Setting</span></a></li>
                                                <li><a href="html/user-profile-activity.html"><em class="icon ni ni-activity-alt"></em><span>Login Activity</span></a></li>
                                                <li><a class="dark-mode-switch" href="#"><em class="icon ni ni-moon"></em><span>Dark Mode</span></a></li>
                                            </ul>
                                        </div>
                                        <div class="dropdown-inner">
                                            <ul class="link-list">
                                                <li><a href="#"><em class="icon ni ni-signout"></em><span>Sign out</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li class="dropdown language-dropdown d-none d-sm-flex me-n1">
                                    <a href="#" class="dropdown-toggle nk-quick-nav-icon" data-bs-toggle="dropdown">
                                        <div class="quick-icon">
                                            <img class="icon" src="./images/flags/english-sq.png" alt=""/>
                                        </div>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-s1">
                                        <ul class="language-list">
                                            <li>
                                                <a href="#" class="language-item">
                                                    <img src="./images/flags/english.png" alt="" class="language-flag"/>
                                                    <span class="language-name">English</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" class="language-item">
                                                    <img src="./images/flags/spanish.png" alt="" class="language-flag"/>
                                                    <span class="language-name">Español</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" class="language-item">
                                                    <img src="./images/flags/french.png" alt="" class="language-flag"/>
                                                    <span class="language-name">Français</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" class="language-item">
                                                    <img src="./images/flags/turkey.png" alt="" class="language-flag"/>
                                                    <span class="language-name">Türkçe</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Navbar
