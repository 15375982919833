// import React from "react";
//  import { Modal } from "react-bootstrap";
// import { AiFillCloseCircle } from "react-icons/ai";

// const VideoPlayModal = ({ modalShow, modalHide, video, children }) => {
//   console.log("this is VideoPlayModal")
//   return (
//     <Modal
    
//       show={modalShow}
//       onHide={modalHide}
//       backdrop="static"
//       keyboard={false}
//       className="video_modal"
//       size="lg"
//       centered
//     >
//       <Modal.Body>
        
//         {children && (
//           <>
//             <span className="close_modal" onClick={modalHide}>
//               <AiFillCloseCircle color="#fff" />
//             </span>
//             {children}
//           </>
//         )}

//         {!children && (
//           <>
//             <span className="close_modal" onClick={modalHide}>
//               <AiFillCloseCircle />
//             </span>
//             <video width="100%" height="400" controls autoplay="true">
//               <source src={video} type="video/mp4" />
//               Your browser does not support the html video tag.
//             </video>
//           </>
//         )}
//       </Modal.Body>
//     </Modal>
//   );
// };
// export default VideoPlayModal;


 
// VideoPlayModal.js
import React, { useState,useEffect  } from 'react';
import { Modal } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';

const VideoPlayModal = ({ modalShow, modalHide, video, children,userdata,AffiliateName,Mobile,emailid}) => {
  // console.log("VideoPlayModal")
  
  const [watermarkTexts, setWatermarkTexts] = useState([]);
  const [watermarkStyleList, setWatermarkStyleList] = useState([]);
  const [watermarkStyles, setWatermarkStyles] = useState([]);
  useEffect(() => {
    // Update watermark texts based on dynamic content (userdata, AffiliateName, Mobile, emailid)
    const watermarkTextList = [
      `${userdata.ip}`,
      `${AffiliateName}`,
      `${emailid}`,
      `${Mobile}`
    ];
    setWatermarkTexts(watermarkTextList);
  }, [userdata, AffiliateName, Mobile, emailid]);
  
  const watermarkStyle = {
    position: 'absolute',
    color: 'red',
    backgroundColor: 'transparent',
    fontSize: '0.8rem',
    zIndex: '98',
    whiteSpace: 'pre-line', // Allow line breaks
    textAlign: 'center',
  };

  const moveWatermarks = () => {
    // Calculate random positions for each watermark
    const watermarkPositions = watermarkTexts.map(() => ({
      top: `${Math.random() * 80}%`, // Adjust as needed
      left: `${Math.random() * 80}%`, // Adjust as needed
    }));

    // Update the watermark styles with the new positions
    setWatermarkStyleList(watermarkPositions);
  };

  useEffect(() => {
    // Move watermarks every 10 seconds (adjust as needed)
    const intervalId = setInterval(moveWatermarks, 10000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [watermarkTexts]);

  return (
    <Modal
      show={modalShow}
      onHide={modalHide}
      backdrop="static"
      keyboard={false}
      className="video_modal"
      size="lg"
      centered
    >
      <Modal.Body>
        <span className="close_modal" onClick={modalHide}>
          <AiFillCloseCircle />
        </span>
        {children ? (
          children
        ) : (
          <video width="100%" height="400" controls autoPlay>
            <source src={video} type="video/mp4" />
            Your browser does not support the html video tag.
          </video>
        )}

      {watermarkTexts.map((text, index) => (
              <div
                key={index}
                style={{
                  ...watermarkStyle,
                  top: watermarkStyleList[index]?.top || '50%', // Default to center if not set
                  left: watermarkStyleList[index]?.left || '50%', // Default to center if not set
                }}
              >
                {text}
              </div>
            ))}
      </Modal.Body>
    </Modal>
  );
};

export default VideoPlayModal;


// VideoPlayModal.js

// import React from 'react';
// import { Modal } from 'react-bootstrap';
// import { AiFillCloseCircle } from 'react-icons/ai';
// import VideoCipherPlayer from './VideoCipherPlayer'; // Import your VideoCipherPlayer component

// const VideoPlayModal = ({ modalShow, modalHide, video, otp, playbackInfo }) => {
//   console.log('Video play modal otp', otp);
//   console.log('Video play modal playbackInfo', playbackInfo);

//   return (
//     <Modal
//       show={modalShow}
//       onHide={modalHide}
//       backdrop="static"
//       keyboard={false}
//       className="video_modal"
//       size="lg"
//       centered
//     >
//       <Modal.Body>
//         <span className="close_modal" onClick={modalHide}>
//           <AiFillCloseCircle />
//         </span>
//         {otp && playbackInfo ? (
//           <VideoCipherPlayer otp={otp} playbackInfo={playbackInfo} />
//         ) : (
//           <video width="100%" height="400" controls autoPlay>
//             <source src={video} type="video/mp4" />
//             Your browser does not support the html video tag.
//           </video>
//         )}
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default VideoPlayModal;
