import './assets/css/dashlite.css?ver=3.1.3';
import './assets/css/theme.css?ver=3.1.3';
import React from 'react';
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom';
 import CourseMaping from './Component/CourseMaping';
 import Demo from './Component/Demo';
 import Affiliate from './Component/Affiliate';
 import Navbar from './Component/Navbar';
import UnAuthorized from './Component/UnAuthorized';
import Lectures from './Component/Lectures';
   
 
function App() {

  return (
    <div className='nk-app-root'>
    <div className='nk-wrap'>
      <Router>
        <Routes>
        <Route exact path="/" element={<UnAuthorized/>}/>
            <Route exact path="/affiliate/:LoginKey" element={<Affiliate/>}/>
            <Route path="/course-maping" element={<CourseMaping/>}/>
            <Route path='/demo' element={<Demo/>}/> 
            <Route path='/lectures/:CourseID/:AffiliateID' element={<Lectures/>}/> 
            <Route path='/navbar' element={<Navbar/>}/> 
           </Routes>
      </Router>  
    </div>
    </div>
     
  );
}

export default App;

