import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Navbar from './Navbar';
import { AuthToken, BASE_URL,BASE_URL1,AuthToken1} from "../Constants";


 
const Demo = () => {


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const CourseID = '04ac814c-5365-4f7a-b91e-f883530871d9'; // Replace with your dynamic value
  const page = 1; // Replace with your dynamic value
  const limit = 10; // Replace with your dynamic value

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          BASE_URL1+'client/live-lectures',
          {
            course_id: CourseID,
            page: page,
            limit: limit,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              API_KEY: AuthToken1,
            },
          }
        );

        setData(response.data.data.lectures);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [CourseID, page, limit, AuthToken1]);
  const columns = [
    {
      name: 'Lecture Title',
      selector: 'LectureTitle',
      sortable: true,
    },
    {
      name: 'Start Time',
      selector: 'StartTime',
      sortable: true,
    },
    {
      name: 'End Time',
      selector: 'EndTime',
      sortable: true,
    },
    {
      name: 'Teacher Name',
      selector: 'teacher.TeacherName',
      sortable: true,
    },
  ];
  
            
       
     

  return (
    <>
    <Navbar/>
  <div className="nk-content nk-content-fluid">
                        <div className="container-xl wide-xl">
                        <div className="nk-content-inner">
                          <div className="nk-content-body">


                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                                  <div className="nk-block-head-content">
                                      <h3 className="nk-block-title page-title">Lectures</h3>
                                      <div className="nk-block-des text-soft">
                                          <p>Welcome to Lectures.</p>
                                      </div>
                                  </div>
                                   
                              </div>
                            </div>

                              

                          

                               
                              <div className="col-md-12"> 
                               

                                  <div className="card card-bordered"> 
                                        <div className="col-md-12">  
                                        <DataTable
                                           columns={columns}
                                          data={data}
                                          pagination
                                          highlightOnHover
                                          responsive
                                           
                                        />
                                        </div>
                                  </div>
                                  
                              </div>     
                        </div>  
                       
                         
                  </div>
                 
          </div>
  </div>              
  </>
  );
};

export default Demo;
