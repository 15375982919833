import React, { useState,useEffect,useRef } from "react";
import { useNavigate,useParams } from "react-router-dom";
import { AuthToken, BASE_URL,BASE_URL1,AuthToken1} from "../Constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';
import DataTable from 'react-data-table-component';
import Box from '@mui/material/Box';
 import { CSVLink } from 'react-csv';
import TextField from '@mui/material/TextField';
import Navbar from './Navbar';
import VideoCipherPlayer from "./VideoCipherPlayer";
import VideoCryptPlayer from "./VideoCryptPlayer";
import VideoPlayModal from "./VideoPlayModal";
import CommonModal from "./CommonModal";
import {AiFillPlayCircle} from "react-icons/ai";
import { getUserIp } from "./ThirdParty";
import { Modal, Button } from "react-bootstrap";
import LiveVideoPlayModal from "./LiveVideoPlayModal";
import VideoModal from "./VideoModal";
import axios from 'axios';


 



function Lectures() {
  

  const params = useParams();
  const { CourseID, AffiliateID } = params;
  console.log("CourseID",CourseID);
  const AffiliateName = localStorage.getItem('AffiliateName');
  const Mobile = localStorage.getItem('mobile');
  const emailid = localStorage.getItem('EmailID');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

    const[Rdata, setRdata] = useState("noloader");
    const [data,setdata]=useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [lectureType, setLectureType] = useState('live'); // Default to 'live' lectures
    const [selectedVideoId, setSelectedVideoId] = useState(null);
    const [videoLink, setVideoLink] = useState('');

    const [selectedLecture, setSelectedLecture] = useState(null);


    const getColumns = () => {
      
      let baseColumns = [
        
        { name: 'Lecture Title', 
        width:'400px', 
        selector: 'LectureTitle', sortable: true,
        
        cell: (row) => <div style={{ width: '400px'}}>{row.LectureTitle}</div>, 
       
      },
      

      ...(lectureType === 'recorded' ? [{
        name: 'Teacher',
        selector: 'TeacherName',
        width: '200px',
        sortable: true,
        cell: (row) => (
          <div style={{ width: '150px' }}>
            {row.users ? `${row.users.TeacherName}` : ''}
          </div>
        ),
      }] : []),
      
      ...(lectureType === 'live' ? [{
        name: 'Teacher',
        selector: 'TeacherName',
        width: '150px',
        sortable: true,
        cell: (row) => (
          <div style={{ width: '150px' }}>
            {row.teacher ? `${row.teacher.TeacherName}` : ''}
          </div>
        ),
      }] : []),

      ...(lectureType === 'recorded' ? [{
        name: 'Shcedule Date',
        selector: 'ShceduleDate',
        width: '150px',
        sortable: true,
        cell: (row) => {
          // Convert the AdmissionDate to the desired format (e.g., "4 Jan 2023")
          const formattedDate = formatDate(row.ShceduleDate);
          return <div style={{ width: '150px' }}>{formattedDate}</div>;
        },
      }] : []),



      ...(lectureType === 'live' ? [
        {
          name: 'Shcedule Date',
          selector: 'ScheduleDate',
          width: '150px',
          sortable: true,
          cell: (row) => {
            // Convert the AdmissionDate to the desired format (e.g., "4 Jan 2023")
            const formattedDate = formatDate(row.ScheduleDate);
            return <div style={{ width: '150px' }}>{formattedDate}</div>; 
          },
        },
        
      ] : []),

        
        ...(lectureType === 'recorded' ? [{
          name: 'Duration',
          selector: 'Duration',
          width: '150px',
          sortable: true,
        }] : []),


        // Conditionally add 'Batch Time' column if lectureType is 'live'
        ...(lectureType === 'live' ? [
          {
            name: 'Start Time',
            selector: 'StartTime',
            width: '150px',
            sortable: true,
          },
          {
            name: 'End Time',
            selector: 'EndTime',
            width: '150px',
            sortable: true,
          },
        ] : []),

        {
          name: 'Views',
          cell: (row) => {
            let displayText;
            let onClickHandler;
        
            if (lectureType === 'live') {
              const currentTime = new Date();
              const scheduleDate = new Date(row.ScheduleDate);
              const startTime = parseTime(row.StartTime);
              const endTime = parseTime(row.EndTime);
              
        
              if (
                scheduleDate.getDate() === currentTime.getDate() &&
                currentTime >= startTime &&
                currentTime <= endTime
              ) {
                displayText = 'Play Live Lecture';
                onClickHandler = () => {
                  if (displayText === 'Play Live Lecture') {
                    viewVideosHandler(row);
                  }
                };
              } else {
                displayText = 'N/A';
                onClickHandler = () => {
                  if (displayText === 'N/A') {
                    // You can add additional logic here if needed
                    //viewVideosHandler(row);
                  }
                };
              }
            } else {
              displayText = 'Play Lecture';
              onClickHandler = () => {
                if (displayText === 'Play Lecture') {
                  viewRecordedVideosHandler(row);
                }
              };
            }
        
            return (
              <div>
                {onClickHandler && (
                  <span
                    style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={onClickHandler}
                  >
                    {displayText}
                  </span>
                )}
                &nbsp;&nbsp;
              </div>
            );
          },
        },
        
        
        
      ];
  
      return baseColumns;
    };

    // const parseTime = (timeString) => {
    //   const [hours, minutes] = timeString.split(':');
    //   return new Date().setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
    // };
    const parseTime = (timeString) => {
      if (timeString) {
        const [hours, minutes] = timeString.split(':');
        return new Date().setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
      } else {
        // Handle the case where timeString is null or undefined
        return null; // You can return a default value or handle it as needed
      }
    };

    const parseDateTime = (dateTimeString) => {
      if (dateTimeString) {
        return new Date(dateTimeString);
      } else {
        // Handle the case where dateTimeString is null or undefined
        return null; // You can return a default value or handle it as needed
      }
    };
    
  
    
  


        // const fetchLecturesData = (lectureType) => {
        //   setRdata('showloader');
        //   const endpoint = lectureType === 'live' ? '/Affiliates/Getlivelecturesbycourseid' : '/Affiliates/Getrecordedlecturesbycourseid';
      
        //   fetch(BASE_URL + endpoint, {
        //     method: 'POST',
        //     body: JSON.stringify({ CourseIDs, AffiliateID }),
        //     headers: {
        //       'Content-Type': 'application/json',
        //       'API_Key': AuthToken,
        //     },
        //   })
        //     .then((response) => response.json())
        //     .then((data) => {
        //       if (data.LiveLectureList !== null) {
        //         setdata(data.LiveLectureList);
        //         setRdata('noloader');
        //       } else {
        //         //toast.error(data.message);
        //         setRdata('noloader');
        //       }
        //     })
        //     .catch((error) => {
        //       console.error('Error:', error);
        //       setRdata('noloader');
        //     });
        // };


        // useEffect(() => {
        //   fetchLecturesData(lectureType);
        // }, [lectureType]); // Fetch data when lectureType changes
      
        // useEffect(() => {
        //   // Fetch initial data when the component mounts
        //   fetchLecturesData(lectureType);
        // }, []);


        


        useEffect(() => {
          const fetchData = async () => {
            try {
              setRdata('showloader');
      
              let apiEndpoint = '';
              if (lectureType === 'live') {
                apiEndpoint = 'client/live-lectures';
              } else if (lectureType === 'recorded') {
                apiEndpoint = 'client/recorded-lectures';
              }
      
              const response = await axios.post(
                BASE_URL1 + apiEndpoint,
                {
                  course_id: CourseID,
                  page: page,
                  limit: limit,
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    API_KEY: AuthToken1,
                  },
                }
              );
      
              setdata(response.data.data.lectures);
              setTotalRows(response.data.data.count);
            } catch (error) {
              console.error('Error fetching data:', error);
            } finally {
              setRdata('noloader');
            }
          };
      
          fetchData();
        }, [page, limit, lectureType]);


        const handlePageChange = (newPage) => {
          setPage(newPage);
        };

        const handleRowsPerPageChange = (newRowsPerPage, currentPage) => {
          setLimit(newRowsPerPage);
          setPage(currentPage);
        };

 
        
      function formatDate(dateString) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const monthIndex = date.getMonth();
        const month = months[monthIndex];
        const year = date.getFullYear().toString().slice(-2);
        return `${day} ${month} ${year}`;
    }
        


  const handleSearch = () => {
    if (searchQuery !== "") {
      const filteredData = data.filter((item) => {
       const fullName = item.users ? `${item.users.TeacherName}` : '';
       const TfullName = item.teacher ? `${item.teacher.TeacherName}` : '';
        
        
        return (
          (item.LectureTitle ? item.LectureTitle.toLowerCase().includes(searchQuery.toLowerCase()) : false) ||
          (fullName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          //(item.users.TeacherName ? item.users.TeacherName.toLowerCase().includes(searchQuery.toLowerCase()) : false) ||
          (TfullName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          //(item.teacher.TeacherName ? item.teacher.TeacherName.toLowerCase().includes(searchQuery.toLowerCase()) : false) ||
          (item.ShceduleDate ? formatDate(item.ShceduleDate) === searchQuery.trim() : false) ||// Check for exact match
          (item.ScheduleDate ? formatDate(item.ScheduleDate) === searchQuery.trim() : false) ||// Check for exact match
          (item.Duration ? item.Duration.toString().includes(searchQuery.trim()) || item.Duration.toString().startsWith(searchQuery.trim()) : false) // Check for partial or starting match
        );
      });
      setSearchResults(filteredData);

    } else {
      setSearchResults(data);
 
    }
  };

   
  
  
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}${currentDate.toLocaleString('default', { month: 'long' })}${currentDate.getFullYear()}`; 
  const csvData = data.map(item => ({
    'Lecture Title': item.LectureTitle,
    'Teacher Name': item.TeacherName,
    'Shcedule Date': item.ShceduleDate,
    'Duration': item.Duration,
    'StartTime':item.StartTime,
    'EndTime':item.EndTime,
  }));

  const labelStyle = (type) => ({
    fontWeight: 'bolder',
    borderBottom: lectureType === type ? '2px solid #798bff' : '1px solid black', // Conditional borderBottom
    color: lectureType === type ? '#798bff' : 'black', // Conditional color
    cursor: 'pointer'
  });




  



  /////////////////
  const [allVideos, setAllVideos] = useState([]);
  const [livevideoModal, setLiveVideoModal] = useState(false);

  const [videoModal, setVideoModal] = useState(false);
  const [playVideoModal, setPlayVideoModal] = useState(false);
  const [playLiveVideoModal, setPlayLiveVideoModal] = useState(false);

  const [cipherLoading, setCipherLoading] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [videoType, setVideoType] = useState("");
  const [Otp, setOtp] = useState("");
  const [playbackInfo, setPlaybackInfo] = useState("");


  

  const viewRecordedVideosHandler = (lecture) => {
    if (lecture) {
      setSelectedLecture(lecture);
  
      if (lecture.IsLive) {
        // If it's a live lecture, handle live lecture video play
        viewLiveVideoHandler(lecture);
      } else {
        // If it's a recorded lecture, handle recorded lecture video play
        viewVideosModalHandler();
      }
    } else {
      toast.error("You can't play this video");
    }
  };




  const viewVideosHandler = (liveLectureID) => {
    if (liveLectureID) {
      setSelectedLecture(liveLectureID);
      viewLiveVideoHandler();
    } else {
      toast.error("error");
    }
  };

  const viewLiveVideoHandler = () => {
    setLiveVideoModal(true);
  };


  const viewVideosModalHandler = () => {
     
    setVideoModal(true);
  };

  // const viewVideosModalHandler = (VideoFile) => {
  //   if(VideoFile !==null){
  //   setVideoModal(true);
  //   }
  // };

  const [lectureDetail, setLectureDetail] = useState({});
  const [channelDetail, setChannelDetail] = useState({});
  const [userData, setUserData] = useState({});
  const [userdata, setuserdata] = useState({});
  const [accessStatus, setAccessStatus] = useState(true);
  const [locationStatus, setLocationStatus] = useState(true);
  const [channelLink, setchannelLink] = useState();
  const [FirstName, setFirstName] = useState();
  const [LastName, setLastName] = useState();
  const [ScheduleDate, setScheduleDate] = useState();
  const [endDateTime, setendDateTime] = useState();

  



  const videoPlayHandler = async (VideoFile) => {
    console.log("dddd");
    console.log(VideoFile);
     
    setSelectedVideo(VideoFile);
  
     
      try {
         setRdata('showloader');
        const { data: ip } = await getUserIp();

        const apiUrl = BASE_URL +'/Affiliates/GetVideoCiper';
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'API_Key': AuthToken,
          },
          body: JSON.stringify({
            lectureid: VideoFile,
            userid:AffiliateID,
            mobile:localStorage.getItem('mobile'),
            EmailID:localStorage.getItem('EmailID'),
            IPAddress: ip.ip,
          }),
        };

        const response = await fetch(apiUrl, requestOptions);
        const res = await response.json();
        
        console.log(res.PlaybackInfo);
        console.log(res.Otp);
        if (res.PlaybackInfo !== null) {
          console.log("In player");
          setOtp(res.Otp || "");
          setPlaybackInfo(res.PlaybackInfo || "");
          setPlayVideoModal(true);
          setVideoModal(false);
          setuserdata({ ...userdata, ip: ip.ip });
          setRdata('noloader');
        } else {
          toast.error("error");
          setRdata('noloader');
        }
      } catch (err) {
        toast.error(err);
        setRdata('noloader');
      }
    
};


const [modalIsOpen, setModalIsOpen] = useState(false);

 

const closeModal = () => {
  setModalIsOpen(false);
};


const livevideoPlayHandler = async (liveLectureID) => {
  // setSelectedVideo(LiveLectureID[0].LiveLectureID);
  setSelectedVideo(liveLectureID);
  
  try {
    setRdata('showloader');

    const { data: ip } = await getUserIp();
    const apiUrl = BASE_URL1+'/client/live-lecture-details';  // Removed double slash before Affiliates

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API_KEY': AuthToken1,
      },
      body: JSON.stringify({
        liveLectureID: liveLectureID,  // Access the correct property here
        courseId: CourseID,
        IPAddress: ip.ip,
      }),
    };

    const response = await fetch(apiUrl, requestOptions);
    console.log("response",response);

    // if (!response.ok) {
    //   throw new Error(`HTTP error! Status: ${response.status}`);
    // }

    const res = await response.json();

    console.log("res", res);

    if (res.code === 200 && res.data.liveDetails) {
      console.log("res.data.liveDetails",res.liveDetails);
      const selectedLecture = res.data.liveDetails;
       let hlsUrl = "";
       if(res.data.liveDetails.ClassRoom == "6"){
        hlsUrl=res.data.liveDetails.apexVideoDetail.hls_url;
       }
       else{
        hlsUrl = res.data.liveDetails.channel.link;
       }
      
      setVideoLink(hlsUrl);
      console.log("selectedLecture",selectedLecture);
      setLectureDetail(selectedLecture || {});
      //setChannelDetail(selectedLecture?.Channel || {});
      setChannelDetail(selectedLecture && selectedLecture.channel ? selectedLecture.channel : {});
      setchannelLink(selectedLecture && selectedLecture.channel ? selectedLecture.channel.link : "")
      console.log("channelLink",channelLink);
      //setVideoLink(selectedLecture && selectedLecture.channel ? selectedLecture.channel.link : "")
      setFirstName(selectedLecture && selectedLecture.teacher ? selectedLecture.teacher.FirstName : "")
      setLastName(selectedLecture && selectedLecture.teacher ? selectedLecture.teacher.LastName : "")

      setScheduleDate(selectedLecture && selectedLecture.ScheduleDate)
      setendDateTime(selectedLecture && selectedLecture.endDateTime)

      console.log("sheduleDate",selectedLecture && selectedLecture.ScheduleDate);
      setUserData({ ...userData, ip: ip.ip });
      
      setModalIsOpen(true);
      setLiveVideoModal(false);
      setRdata('noloader');
    } else {
      toast.error(res.message || 'Invalid API response');
      setRdata('noloader');
    }
  } catch (err) {
    toast.error(err.message || 'An error occurred');
    setRdata('noloader');
  }
};
  
  return (
    <>
    <Navbar/>
  <div className="nk-content nk-content-fluid">
        <div className="container-xl wide-xl">
                <div className="nk-content-inner">
                          <div className="nk-content-body">
                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                                  <div className="nk-block-head-content">
                                      <h3 className="nk-block-title page-title">Lectures</h3>
                                      <div className="nk-block-des text-soft">
                                          <p>Welcome to Lectures.</p>
                                      </div>
                                  </div>

                                  


                                  <div className="nk-block-head-content">
                                      <div className="toggle-wrap nk-block-tools-toggle">
                                          <a href="#" className="btn btn-icon btn-trigger toggle-expand me-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
                                          <div className="toggle-expand-content" data-content="pageMenu">
                                              <ul className="nk-block-tools g-3">
                                                  {/* <li><a onClick={() => document.querySelector('.csv-link').click()} className="btn btn-white btn-dim btn-outline-primary"><em class="icon ni ni-download-cloud"></em><span>Export</span></a></li> */}
                                                  {data.length && data.length > 0 ? (
                                                    <li>
                                                      
                                                       <a onClick={() => document.querySelector('.csv-link').click()} className="btn btn-white btn-dim btn-outline-primary">
                                                        <em class="icon ni ni-download-cloud rotate-up"></em>
                                                        <span>Export</span>
                                                      </a>
                                                    </li>
                                                    
                                                ) : null}
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>

                              

                          {data.length > 0 ?
                              <CSVLink className="csv-link" data={csvData} filename={`Lectures-${formattedDate}.csv`} style={{display: 'none'}}>
                                  Export to CSV
                              </CSVLink> :""
                          } 

                               
                              <div className="col-md-12"> 

                                <div className="row">
                                <div>
                                  <label
                                    style={labelStyle('live')}
                                    onClick={() => setLectureType('live')}>
                                    Live Lectures
                                  </label>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <label
                                    style={labelStyle('recorded')}
                                    onClick={() => setLectureType('recorded')}>
                                    Course Lectures
                                  </label>
                                </div>
                                </div>

                                <br></br>

                              <div>
                                  <TextField
                                  type="text"
                                  id="txtsearch"
                                  size="small"
                                  // label="Search..."
                                  placeholder="Search"
                                  value={searchQuery}
                                  onChange={(e) => setSearchQuery(e.target.value)}
                                  
                                  />&nbsp;&nbsp;&nbsp;&nbsp;
                                     <Button class="btn btn-primary" onClick={handleSearch} style={{height:"39px"}}>
                                      Search
                                  </Button>   
                                  </div>
                                  <br></br>

                                  <div className="card card-bordered"> 
                                        <div className="col-md-12">  
                                          <DataTable
                                          columns={getColumns()}
                                          data={searchResults.length > 0 ? searchResults : data}
                                          //data={data}
                                         // getRowId={(row) =>  row.StudentID}
                                         // pagination={true}
                                         pagination
                                         paginationServer
                                         paginationPerPage={limit}
                                         paginationRowsPerPageOptions={[10, 20, 40, 60, 100]}
                                         paginationTotalRows={totalRows} 
                                         paginationComponentOptions={{
                                           rowsPerPageText: 'Rows per page:',
                                           rangeSeparatorText: 'of',
                                           noRowsPerPage: false,
                                           selectAllRowsItem: false,
                                           selectAllRowsItemText: 'All',
                                         }}
                                         onChangeRowsPerPage={handleRowsPerPageChange}
                                         onChangePage={handlePageChange}
                                          />
                                        </div>
                                  </div>
                              </div>   


                              {Rdata === "showloader"?
                                              <Backdrop   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}  open>
                                              <CircularProgress color="inherit" />
                                          </Backdrop>    :""
                                          }
                                  <ToastContainer />  


                                  {/* {selectedVideoId && 
                                  <VideoCipherPlayer
                                   videoId={selectedVideoId} 
                                   />} */}

                        </div>  
                  </div>
          </div>
  </div> 


  
<CommonModal
  show={videoModal}
  handleClose={() => setVideoModal(false)}
  className={"modal-sm"}
  title={"Course Lecture"}
  
>
  <div className="course_listing" style={{display:"flex",marginTop:"20px"}}>
    
    <p>
      {selectedLecture ? selectedLecture.LectureTitle : 'Loading...'}
    </p>
    
    <button
    style={{position: "absolute",right: "15px", top: "60px", height: "31px",height:"31px",width:"31px",color:"red"}}
      type="button"
      className="button"
      onClick={() => {
        videoPlayHandler(selectedLecture ? selectedLecture.VideoFile : null);
        
      }}
    >
      <AiFillPlayCircle />
    </button>
  </div>
</CommonModal>

      <VideoPlayModal
        modalShow={playVideoModal}
        modalHide={() => setPlayVideoModal(false)}
        otp={Otp}
        playbackInfo={playbackInfo}
        AffiliateName={AffiliateName}
        userdata={userdata}
        Mobile={Mobile}
        emailid={emailid}
      >
          <>
          
            {playbackInfo && Otp &&  (
              <VideoCipherPlayer otp={Otp} playbackInfo={playbackInfo} />
            )}
            
          </>
       
      </VideoPlayModal>


  <CommonModal
  show={livevideoModal}
  handleClose={() => setLiveVideoModal(false)}
  className={"modal-sm"}
  title={"Live Lecture"}
  
>
  <div className="course_listing" style={{display:"flex",marginTop:"20px"}}>
    
    <p>
      {selectedLecture ? selectedLecture.LectureTitle : 'Loading...'}
    </p>
    
    <button
    style={{position: "absolute",right: "15px", top: "60px", height: "31px",height:"31px",width:"31px",color:"red"}}
      type="button"
      className="button"
      onClick={() => {
        livevideoPlayHandler(selectedLecture ? selectedLecture.liveLectureID : null);
        
      }}
    >
      <AiFillPlayCircle />
    </button>
  </div>
</CommonModal>

{/* <LiveVideoPlayModal
        modalShow={playLiveVideoModal}
        modalHide={() => setPlayLiveVideoModal(false)}
        channelDetail={channelLink}
        userData={userData}
        video={channelLink} // Add this line
      >
          <>
          
          {channelLink && (
                          <VideoCryptPlayer
                            src={channelLink}
                            userData={userData}
                          />
                        )}
            
          </>
       
      </LiveVideoPlayModal> */}

      <VideoModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        videoLink={videoLink}
        lectureDetails={lectureDetail} // Pass additional lecture details to the modal if needed
        userData={userData}
        AffiliateName={AffiliateName}
        Mobile={Mobile}
        emailid={emailid}
        FirstName={FirstName}
        LastName={LastName}
        ScheduleDate={ScheduleDate}
        endDateTime={endDateTime}
       
      />

  </>
  )
}

export default Lectures
