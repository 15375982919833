import React, { useState,useEffect  } from 'react';
import Modal from 'react-modal';
import VideoCryptPlayer from './VideoCryptPlayer';
import ReactPlayer from 'react-player';
import { FaRegCalendarAlt } from "react-icons/fa";
import {
  liveLectureLinkCreation,
  momentDateFormat,
  timeFormatWith12Hour,
} from "./Utils";

 
const VideoModal = ({ isOpen, closeModal, videoLink,lectureDetails,userData,AffiliateName,Mobile,emailid,FirstName,LastName,ScheduleDate,endDateTime}) => {
    console.log("videoLink",videoLink);
    const customStyles = {
        content: {
          marginTop: '55px', // Add the top margin here
        },
      };
      const [watermarkTexts, setWatermarkTexts] = useState([]);
      const [watermarkStyleList, setWatermarkStyleList] = useState([]);
      const [watermarkStyles, setWatermarkStyles] = useState([]);


      useEffect(() => {
        // Update watermark texts based on dynamic content (userData, AffiliateName, Mobile, emailid)
        const watermarkTextList = [
          `${userData.ip}`,
          `${AffiliateName}`,
          `${emailid}`,
          `${Mobile}`
        ];
        setWatermarkTexts(watermarkTextList);
      }, [userData, AffiliateName, Mobile, emailid]);
      
      const watermarkStyle = {
        position: 'absolute',
        color: 'red',
        backgroundColor: 'transparent',
        fontSize: '0.8rem',
        zIndex: '98',
        whiteSpace: 'pre-line', // Allow line breaks
        textAlign: 'center',
      };

      const moveWatermarks = () => {
        // Calculate random positions for each watermark
        const watermarkPositions = watermarkTexts.map(() => ({
          top: `${Math.random() * 80}%`, // Adjust as needed
          left: `${Math.random() * 80}%`, // Adjust as needed
        }));
    
        // Update the watermark styles with the new positions
        setWatermarkStyleList(watermarkPositions);
      };

      useEffect(() => {
        // Move watermarks every 10 seconds (adjust as needed)
        const intervalId = setInterval(moveWatermarks, 10000);
    
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
      }, [watermarkTexts]);
    

  return (
    <Modal
     
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Video Modal"
      style={customStyles} // Apply custom styles
      
      
    >
        
       
       {/* <h5>{lectureDetails.LectureTitle}</h5> */}

       {/* Watermark */}
      {/* <div
        style={{
          
          position: 'absolute',
          
          color: 'red',
          backgroundColor: 'transparent',
          fontSize: '0.8rem',
          zIndex: '98',
          display:"inline-block"
        }}
      >
         
        {watermarkText}
      </div> */}
    <div style={{textAlign:"right"}}>
      <img
                src='/Images/close.png'
                width="30"
                height="30"
                marginTop="14px"
                textAlign="center"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
                style={{backgroundColor:"white",textAlign:"center",marginLeft:"120px", cursor:"pointer"}}
                onClick={closeModal}
                
              />
      </div>  

         

      {/* Video Player */}

      
      <ReactPlayer
        url={videoLink}
        controls
        width="100%"
        height="100%"
        onError={(error) => console.error('Player error:', error)}
      />

                    <div className="course_video">
                      <div className="video_wrap">

                        
                        <div className="about_video">
                          <div className="content">
                            <h5>{lectureDetails.LectureTitle}</h5>
                            <ul>
                              <li>
                                <FaRegCalendarAlt />
                                {`${momentDateFormat(
                                  lectureDetails.ScheduleDate
                                )} ${timeFormatWith12Hour(
                                  lectureDetails.StartTime
                                )}`}
                              </li>
                              <li className="red">
                                <FaRegCalendarAlt />
                                {`${momentDateFormat(
                                  lectureDetails.endDateTime
                                )} ${timeFormatWith12Hour(
                                  lectureDetails.EndTime
                                )}`}
                              </li>
                            </ul>
                          </div>
                        </div>
                        
                        <div style={{marginTop:"4px"}}>
                        <button class="btn btn-primary" onClick={closeModal}>Close Video</button>
                        </div>

                        </div>
                    </div>


         {/* Dynamic Watermarks */}
      {watermarkTexts.map((text, index) => (
        <div
          key={index}
          style={{
            ...watermarkStyle,
            top: watermarkStyleList[index]?.top || '50%', // Default to center if not set
            left: watermarkStyleList[index]?.left || '50%', // Default to center if not set
          }}
        >
          {text}
        </div>
      ))}


      
       {/* <h5>{lectureDetails.LectureTitle}</h5>
      <VideoCryptPlayer src={videoLink} userData={userData} />
      <button onClick={closeModal}>Close Video</button> */}
    </Modal>
  );
};

export default VideoModal;
