import { useEffect,useState,useRef} from 'react';
import { useNavigate,useParams  } from "react-router-dom";
import { AuthToken, BASE_URL,LOGO_URL } from "../Constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';

const Affiliate = () => {
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();
    const[Rdata, setRdata] = useState("noloader");
    const { LoginKey } = useParams();

  // useEffect(() => {
  //   // const hashedPassword = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u');

  //   fetch(BASE_URL + '/Affiliates/VerifyAffiliateEXELogin', {
  //     method: 'POST',
  //     body: JSON.stringify({LoginKey: LoginKey}),
  //     headers: {
  //       'Content-Type': 'application/json',
  //       "API_Key":AuthToken,
  //      },
  //   })
  //   .then((response) => {
  //       console.log(response);
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data.status === 'success') {
          
  //         console.log("response",data.AffiliateUser)
  //         localStorage.setItem('AffiliateID', data.AffiliateUser.AffiliateID.toString());
  //         console.log("AffiliateID",data.AffiliateUser.AffiliateID.toString());
  //         localStorage.setItem('AffiliateName', data.AffiliateUser.AffiliateName);
  //         navigate('/course-maping')
  //         //toast.success(data.message);
  //       } 
  //       else 
  //       {
  //         setLoginError('You are not authorize to view this page');
           
  //       }
  //     })
  //     .catch((error) => {
  //       setLoginError('An error occurred during login. Please try again.');
  //     });
  // }, []);





  const fetchData = async () => {
    setRdata("showloader");
     
    fetch(BASE_URL + "/Affiliates/VerifyAffiliateEXELogin", {
      method: "POST",
      body: JSON.stringify({LoginKey: LoginKey}),
      headers: {
        "Content-Type": "application/json",
        "API_Key": AuthToken
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "Success") {

          const affiliateID = data.Data[0].AffiliateID;
          const mobile = data.Data[0].Mobile;
          const emailid = data.Data[0].EmailID;
          const affiliatename = data.Data[0].AffiliateName;

          localStorage.setItem("AffiliateID", affiliateID);
          localStorage.setItem("AffiliateName", affiliatename);
          localStorage.setItem("mobile", mobile);
          localStorage.setItem("EmailID", emailid);

          

          navigate('/course-maping')
          setRdata("noloader");

        } else {
          setLoginError('You are not authorize to view this page');
          setRdata("noloader");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
       setRdata("noloader");
      });
  };
  
  
  useEffect(() => {  
    fetchData();        
     },[]);


  return (
    <form className="App-logo"> 
    <div className="nk-app-root">
        <div className="nk-main">
            <div className="nk-wrap nk-wrap-nosidebar">
                    <div className="nk-content ">
                        <div className="nk-block nk-block-middle nk-auth-body  wide-xss">
                           
                            <div className="card card-bordered">    
                                <div className="card-inner card-inner-lg">
                                <div className="brand-logo pb-4 text-center">
                                    <img width={80} className="logo" src="/Images/srlogo.png" alt="logo" />
                                </div>
                                    <div className="nk-block-head">
                                        <div className="nk-block-head-content" style={{textAlign:"center"}}>
                                            <h5 className="nk-block-title" style={{color:"red"}}>'You are not authorize to view this page'</h5>
                                        </div>
                                    </div>
                                  
                                </div>
                                
                            </div>
                        </div>
                                  {Rdata === "showloader"?
                                      <Backdrop   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}  open>
                                      <CircularProgress color="inherit" />
                                  </Backdrop>    :""
                                  }
                        
                    </div>
                   
                </div>
            </div>
        </div>
    
        </form>
  );
};

export default Affiliate;
